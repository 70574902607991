import React from "react";
import {
  BrowserRouter as Router,
  Route,
  useParams,
  Routes,
} from "react-router-dom";

function RedirectToTelegram() {
  // Capture the dynamic part of the URL after "/"
  const { username } = useParams();

  // Construct the Telegram URL with the username
  const telegramUrl = `https://t.me/${username}`;

  // Perform the redirection after a delay
  React.useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = telegramUrl;
    }, 2000); // Delay of 2 seconds

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [telegramUrl]);

  // Render a spinner while waiting to redirect
  return (
    <div style={styles.container}>
      <div style={styles.spinner}></div>
    </div>
  );
}

function Home() {
  // Render Telegram's logo on a blue background
  return (
    <div style={styles.homeContainer}>
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/8/82/Telegram_logo.svg"
        alt="Logo"
        style={styles.logo}
      />
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        {/* Root URL */}
        <Route path="/" element={<Home />} />

        {/* Dynamic path to redirect */}
        <Route path="/:username" element={<RedirectToTelegram />} />
      </Routes>
    </Router>
  );
}

export default App;

// Styles
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  spinner: {
    width: "50px",
    height: "50px",
    border: "6px solid #f3f3f3",
    borderTop: "6px solid #007bff", // Primary color for the spinner
    borderRadius: "50%",
    animation: "spin 1s linear infinite", // Spinner rotates every 2 seconds
  },
  homeContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "white",
  },
  logo: {
    width: "150px",
    height: "150px",
  },
};

// Add spinner animation to the global styles
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
document.head.appendChild(styleSheet);
